import BaseStyles from '../base.js';
import merge from 'deepmerge';

export default class LuauStyles extends BaseStyles {

  getPalette() {
    return merge(super.getPalette(), {
      /* Colors Palette */
      primary: '#005462',
      primaryContrast: '#BFDCE0',
      accent: '#F9B93A',
      accentContrast: '#FFFFFF',
    });
  }

  getStyles() {
    const palette = this.getPalette();
    return merge(super.getStyles(), {
      /* Styles */
    });
  }

  getTheme() {
    return merge(super.getTheme(), {
      /* Theme properties */
      splashVideo: require('./assets/SplashVideo.mp4'),
      splashImage: require('./assets/SplashScreen.png'),
      logo: require('./assets/Logo.png'),
      logoColor: require('./assets/LogoColor.png'),
      islandBackground: require('./assets/TheIslandBackground.png'),
      assets: {}
    });
  }

}
